<template>
    <div>
        loading
    </div>
</template>

<script>
export default {
  mounted () {
    this.$router.push({ path: '/home' })
  }
}
</script>

<style>
</style>
